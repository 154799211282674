<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>全渠道分销</el-breadcrumb-item>
            <el-breadcrumb-item>渠道商列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>名称：</label>
                  <el-input class="width-220"  placeholder="名称" v-model="param.name" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>等级：</label>
                  <el-select v-model="param.level"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="item in levelList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>类型：</label>
                  <el-select v-model="param.type"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                        v-for="item in typeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>创建时间：</label>
                  <date-packer class="width-220" :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <el-row class="table-head-btn">
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" icon="el-icon-plus"  @click="handleAddEdit('add')"><span>添加渠道商</span></el-button>
               </el-row>
            </el-row>
            <el-table
               ref="mainTable"
               :data="tableData"
               :stripe="true"
               border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="name" label="名称" min-width="50"></el-table-column>
               <el-table-column label="等级" min-width="50">
                  <template slot-scope="scope">
                     {{ scope.row.level | filterLevel(that) }}
                  </template>
               </el-table-column>
               <el-table-column label="类型" min-width="50">
                  <template slot-scope="scope">
                     {{ scope.row.type | filterType(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="legalPerson" label="联系人姓名" min-width="50"></el-table-column>
               <el-table-column prop="mobile" label="联系电话" min-width="50"></el-table-column>
               <el-table-column prop="chudan" label="出单量" min-width="40"></el-table-column>
               <el-table-column prop="xiaoshou" label="销售总额" min-width="50"></el-table-column>
               <el-table-column prop="fenrun" label="分润总额" min-width="50"></el-table-column>
               <el-table-column prop="status" label="合作状态" min-width="40">
                  <template slot-scope="scope">
                     {{ scope.row.status | filterStatus(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="createTime" label="合作时间" min-width="70"></el-table-column>
               <el-table-column :label="$t('msg.operating')" min-width="80" sortable fixed="right">
                  <template slot-scope="scope">
                     <el-button @click="handleDetail(scope.row)" type="text" >
                        <el-link type="primary">详情</el-link>
                     </el-button>
                     <el-button @click="handleAddEdit('edit', scope.row)" type="text" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button style="color: #F56C6C;" @click="handleDel(scope.row.id)" type="text" >
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
         </el-row>
         <!-- 详情 -->
         <el-dialog title="渠道商详情" :visible.sync="visible" width="700px" :before-close="handleClose">
            <el-form class="form-dialog-box">
               <el-form-item label="渠道商名称">
                  <b>{{ detail.name }}</b>
                  <el-tag class="m-left-10" type="success" >{{ detail.status }}</el-tag>
               </el-form-item>
               <el-row class="form-row">
                  <el-form-item label="类型">
                     {{ detail.type | filterType }}
                  </el-form-item>
                  <el-form-item label="联系人">
                     {{ detail.legalPerson }}
                  </el-form-item>
               </el-row>
               <el-row class="form-row">
                  <el-form-item label="等级">
                     {{ detail.level | filterLevel }}
                  </el-form-item>
                  <el-form-item label="联系电话">
                     {{ detail.mobile }}
                  </el-form-item>
               </el-row>
               <el-form-item label="Logo">
                  <el-row class="popup-img">
                     <img class="width-150" :src="detail.img01" alt="">
                     <img class="width-150" :src="detail.img02" alt="">
                  </el-row>
               </el-form-item>
            </el-form>
            <span slot="footer">
               <el-button class="bg-gradient" @click="visible = false">关闭</el-button>
            </span>
         </el-dialog>
         <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
   </div>
</template>

<script>
import {urlObj } from "@/api/interface";
import { distributor } from "@/api/interface/business"
import { mapState } from 'vuex'
import { getDict } from "@/common/js/common";
export default {
   data(){
      return{
         tableData: [],
         total: 0,
         limit: 1,
         page: 1,
         visible: false,
         that: this,
         dateValue: [],
         detail: {
            name: '',
            status: '',
            type: '',
            legalPerson: '',
            level: '',
            mobile: '',
            img01: '',
            img02: '',
         },
         param: {
            name: '',
            level: '',
            type: '',
            beginTime: '',
            endTime: ''
         },
         levelList: [
            { label: '全部', value: '' },
            { label: '一级', value: 'ONE_LEVEL' },
            { label: '二级', value: 'TWO_LEVEL' },
            { label: '三级', value: 'THREE_LEVEL' }
         ],
         typeList: [
            { label: '全部', value: '' },
            { label: '旅行社', value: 'TRAVEL_AGENCY' },
            { label: '企业员工', value: 'EMPLOYEE' },
            { label: '其他分销商', value: 'OTHER' }
         ],
         del_success: '删除成功！',
         confirm_remove: '确定移除？',
         prompt: '提示！',
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData'])
   },
   mounted() {
      this.limit = sessionStorage.getItem('pageSize') * 1
      getDict(['distributor-type', 'distributor-level', 'cooperation-status'])
      this.getDistributors()
   },
   methods: {
      // 获取渠道商列表
      getDistributors(){
         const url = distributor.distributorPage + `?page=${this.page}&limit=${this.limit}`
         const param = { hotelId: this.hotelInfo.id, ...this.param }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      // 改变当前日期
      getDatePacker(val){
         this.dateValue = val
         let [ beginTime = '', endTime = '' ] = val
         this.param.beginTime = beginTime
         this.param.endTime = endTime
      },
      // 查询渠道商
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getDistributors()
         for (let key in this.param) this.param[key] = ''
         this.dateValue = []
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getDistributors()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getDistributors()
      },
      // 添加/编辑
      handleAddEdit(action, row){
         if (action === 'edit') sessionStorage.setItem('distributorInfo', JSON.stringify(row))
         this.$router.push({path: '/channel_manage/channel_add', query: { action }})
      },
      // 删除
      handleDel(id){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            const url = distributor.delDistributor + `/${id}`
            this.$axios.get(url, {}).then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.del_success,
                     type: 'success'
                  })
                  this.getDistributors()
               }
            })
         })
      },
      // 详情
      handleDetail(row){
         this.visible = true
         for (let key in row) key in this.detail && (this.detail[key] = row[key])
         if (row.attachment) {
            let attachment = JSON.parse(row.attachment)
            if (attachment.length > 0) {
               let imgObj1 = attachment[0]
               this.detail.img01 = window.getResourcesHost() + `${imgObj1.location}` + `/${imgObj1.formatName}`
            }
            if (attachment.length > 1) {
               let imgObj2 = attachment[1]
               this.detail.img02 = window.getResourcesHost() + `${imgObj2.location}` + `/${imgObj2.formatName}`
            }
         }
      },
      // 关闭详情弹窗
      handleClose(){
         this.visible = false
         this.detail.img01 = ''
         this.detail.img02 = ''
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getDistributors()
      }
   },
   filters: {
      filterLevel(val, that){
         if (val && that.dictData['distributor-level']) return that.dictData['distributor-level'][val] || val
      },
      filterType(val, that){
         if (val && that.dictData['distributor-type']) return that.dictData['distributor-type'][val] || val
      },
      filterStatus(val, that) {
         if (val && that.dictData['cooperation-status']) return that.dictData['cooperation-status'][val] || val
      }
   }
}
</script>

<style lang="scss" scoped>
.cont{min-width: 1200px !important;}
   .popup-box{
      .popup-cont{
         padding-top: 30px; width: 90%; margin: 0 auto;
         .el-row{
            margin: 0 0 20px 0;
         }
         .popup-img{
            margin-top: 30px;
            img{ width: 100px; margin: 0 20px 0 0 }
         }
         .btm-btn{ text-align: center }
      }
   }
</style>
